*,
*:before,
*:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: inherit;
    background-color: transparent;
}

body {
    font-family: sans-serif;
    background-color: #313335;
    padding: 1rem;
    color: white;
}

textarea,
button,
input {
    border: 1px solid white;
    font-family: monospace;
    padding: 0.25rem;
}

button,
.button {
    cursor: pointer;
    border: none;
    font-size: 1.2rem;
    padding: 0.5rem;
    margin: 0 0.5rem;
    text-decoration: underline;
}

button:hover,
.button:hover {
    color: black;
    background-color: white;
}

.bot-card-container {
    display: flex;
    gap: 2rem;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    overflow: hidden;
}

.card {
    background-color: #232525;
    box-shadow: 0 0 5px 3px #0002;
    margin: 1rem;
    padding: 0.5rem;
}

.bot-card {
    width: 35rem;
    text-align: center;
}

.bot-name {
    font-size: 1.4rem;
    margin-bottom: 1rem;
    height: 5rem;
}

.accepted {
    color: green;
}

.rejected {
    color: red;
}

.bot-icon {
    border-radius: 50%;
    background-color: #333;
}

.bot-rank {
    font-size: 2rem;
    height: 4rem;
    background-color: #444;
}

.bot-icon-small {
    width: 4rem;
    height: 4rem;
    font-size: 2rem;
}

.bot-icon-big {
    width: 12rem;
    height: 12rem;
}

.bot-icon-big-container {
    position: relative;
    width: 12rem;
    height: 12rem;
    margin: auto;
}

.bot-icon-tag {
    position: absolute;
    right: 0.25rem;
    top: 0.25rem;
}

.modal {
    position: absolute;
    width: 40rem;
    height: 40rem;
    left: calc(50vw - 20rem);
    top: calc(50vh - 20rem);
    box-shadow: 0 0 5px 3px #f004;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal pre {
    flex-grow: 1;
    overflow: auto;
}

td,
th {
    padding: 0.25rem 1rem;
    text-align: right;
    transition: background-color 0.5s ease-in-out;
}

.big-number {
    font-size: 3rem;
    text-align: center;
}

.score-zero {
    color: grey;
}

.score-one {
    font-weight: bold;
    letter-spacing: 0.15em;
}

.bot-ranks {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 0 0.2rem;
}

.rank-image-active {
    background-color: #444;
    padding: 0.5rem;
}

.rank-image {
    padding: 0.5rem;
    transition: background-color 0.5s ease;
}

h2 {
    color: #eee;
}

*+h4,
*+h3 {
    margin-top: 1rem;
}

.bot-stats {
    display: flex;
    text-align: center;
    margin: 2rem;
    gap: 2rem;
    justify-content: space-around;
}

pre {
    margin: 1rem;
    padding: 1rem;
}

@keyframes come-from-left {
    0% {
        transform: translateX(-100vw)
    }

    100% {
        transform: translateX(0)
    }
}

@keyframes go-up {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-100vh);
    }
}

@keyframes go-down {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(100vh);
    }
}

.animate-in {
    animation: come-from-left 0.5s ease-out;
}

.animate-up {
    animation: go-up 0.5s ease-in;
    transform: translateY(-100vh);
}

.animate-down {
    animation: go-down 0.5s ease-out;
    transform: translateY(100vh);
}

td.active-column,
th.active-column {
    background-color: #352f2f;
}

td.active-row,
th.active-row {
    background-color: #2f2f35;
}

td.active-row.active-column,
th.active-row.active-column {
    background-color: #353f35;
}

table {
    border-spacing: 0;
}

p {
    margin-bottom: 0.4rem;
}