*, :before, :after {
  box-sizing: border-box;
  color: inherit;
  background-color: #0000;
  margin: 0;
  padding: 0;
}

body {
  color: #fff;
  background-color: #313335;
  padding: 1rem;
  font-family: sans-serif;
}

textarea, button, input {
  border: 1px solid #fff;
  padding: .25rem;
  font-family: monospace;
}

button, .button {
  cursor: pointer;
  border: none;
  margin: 0 .5rem;
  padding: .5rem;
  font-size: 1.2rem;
  text-decoration: underline;
}

button:hover, .button:hover {
  color: #000;
  background-color: #fff;
}

.bot-card-container {
  place-content: center space-around;
  align-items: center;
  gap: 2rem;
  display: flex;
  overflow: hidden;
}

.card {
  background-color: #232525;
  margin: 1rem;
  padding: .5rem;
  box-shadow: 0 0 5px 3px #0002;
}

.bot-card {
  width: 35rem;
  text-align: center;
}

.bot-name {
  height: 5rem;
  margin-bottom: 1rem;
  font-size: 1.4rem;
}

.accepted {
  color: green;
}

.rejected {
  color: red;
}

.bot-icon {
  background-color: #333;
  border-radius: 50%;
}

.bot-rank {
  height: 4rem;
  background-color: #444;
  font-size: 2rem;
}

.bot-icon-small {
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
}

.bot-icon-big {
  width: 12rem;
  height: 12rem;
}

.bot-icon-big-container {
  width: 12rem;
  height: 12rem;
  margin: auto;
  position: relative;
}

.bot-icon-tag {
  position: absolute;
  top: .25rem;
  right: .25rem;
}

.modal {
  width: 40rem;
  height: 40rem;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: absolute;
  top: calc(50vh - 20rem);
  left: calc(50vw - 20rem);
  box-shadow: 0 0 5px 3px #f004;
}

.modal pre {
  flex-grow: 1;
  overflow: auto;
}

td, th {
  text-align: right;
  padding: .25rem 1rem;
  transition: background-color .5s ease-in-out;
}

.big-number {
  text-align: center;
  font-size: 3rem;
}

.score-zero {
  color: gray;
}

.score-one {
  letter-spacing: .15em;
  font-weight: bold;
}

.bot-ranks {
  grid-template-columns: repeat(6, 1fr);
  gap: 0 .2rem;
  display: grid;
}

.rank-image-active {
  background-color: #444;
  padding: .5rem;
}

.rank-image {
  padding: .5rem;
  transition: background-color .5s;
}

h2 {
  color: #eee;
}

* + h4, * + h3 {
  margin-top: 1rem;
}

.bot-stats {
  text-align: center;
  justify-content: space-around;
  gap: 2rem;
  margin: 2rem;
  display: flex;
}

pre {
  margin: 1rem;
  padding: 1rem;
}

@keyframes come-from-left {
  0% {
    transform: translateX(-100vw);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes go-up {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100vh);
  }
}

@keyframes go-down {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100vh);
  }
}

.animate-in {
  animation: .5s ease-out come-from-left;
}

.animate-up {
  animation: .5s ease-in go-up;
  transform: translateY(-100vh);
}

.animate-down {
  animation: .5s ease-out go-down;
  transform: translateY(100vh);
}

td.active-column, th.active-column {
  background-color: #352f2f;
}

td.active-row, th.active-row {
  background-color: #2f2f35;
}

td.active-row.active-column, th.active-row.active-column {
  background-color: #353f35;
}

table {
  border-spacing: 0;
}

p {
  margin-bottom: .4rem;
}

/*# sourceMappingURL=index.dfa96331.css.map */
